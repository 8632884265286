export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "kube_control_plane_cmds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kube Control Plane"])},
        "get_nodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List nodes"])},
        "get_namespaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List namespaces"])},
        "get_all_pods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List all pods"])},
        "get_kube_system_pods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List pods in kube-system"])},
        "view_config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View cluster config"])},
        "cluster_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View cluster version"])},
        "etcd_cmds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETCD"])},
        "etcd_member_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List etcd members"])},
        "etcd_member_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List ETCD Endpoint status"])},
        "kube_node_cmds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kube Node"])},
        "kubelet_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View kubelet version"])},
        "kubelet_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View kubelet status"])},
        "kubelet_journal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View kubelet logs"])},
        "crictl_ps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List containers"])},
        "crictl_images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List images"])}
      },
      "zh": {
        "kube_control_plane_cmds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["控制节点的常用命令"])},
        "get_nodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获取节点列表"])},
        "get_namespaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获取名称空间列表"])},
        "get_all_pods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获取所有容器组列表"])},
        "get_kube_system_pods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获取 kube-system 容器组列表"])},
        "view_config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看集群配置"])},
        "cluster_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看集群版本"])},
        "etcd_cmds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETCD节点的常用命令"])},
        "etcd_member_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETCD成员列表"])},
        "etcd_member_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETCD Endpoint状态"])},
        "kube_node_cmds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作节点的常用命令"])},
        "kubelet_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看 kubelet 版本"])},
        "kubelet_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看 kubelet 状态"])},
        "kubelet_journal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看 kubelet 日志"])},
        "crictl_ps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看容器组列表"])},
        "crictl_images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看容器镜像列表"])},
        "calico_node_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calico 节点列表"])},
        "calico_node_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calico 节点状态"])}
      }
    }
  })
}
