export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "terminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["terminal"])},
        "find": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find"])},
        "connecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecting"])},
        "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closing"])},
        "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])},
        "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown"])}
      },
      "zh": {
        "terminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["终端"])},
        "find": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查 找"])},
        "connecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在连接"])},
        "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已连接"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在关闭"])},
        "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已断开"])},
        "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未知状态"])}
      }
    }
  })
}
